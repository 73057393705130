<template>
    <div>
        <CCard class="zw-page">
            <CCardBody>
                <zw-table ref="table"
                          tableName="customer_calls2"
                          tableFormat="App\Managers\Format\Reports\CustomerCalls2"
                          :items="myProvider"
                          :fields="fields"
                          :filter="filter"
                          :selected="selected"
                          title="common.menu.reports.customer_calls2"
                          :actions-list="getActions()"
                >
                    <template v-slot:top-actions>
                        <b-form inline>
                            <font-awesome-icon
                                role="button"
                                icon="arrow-left"
                                @click="prevMonth()"
                            />&nbsp;
                            <b-form-select
                                v-model="month"
                                :options="monthsList()"
                                class="mr-2"
                                @change="monthChanged"
                            ></b-form-select>
                            <font-awesome-icon
                                role="button"
                                icon="arrow-right"
                                @click="nextMonth()"
                            />
                        </b-form>
                    </template>

                    <template #cell(status_1)="row">
                        <div v-if="row.item.status_1">
                            <a @click="openCustomers('status_1',row.item.data)" href="#">{{ row.item.status_1 }}</a>
                        </div>
                    </template>
                    <template #cell(status_2)="row">
                        <div v-if="row.item.status_2">
                            <a @click="openCustomers('status_2',row.item.data)" href="#">{{ row.item.status_2 }}</a>
                        </div>
                    </template>
                    <template #cell(status_3)="row">
                        <div v-if="row.item.status_3">
                            <a @click="openCustomers('status_3',row.item.data)" href="#">{{ row.item.status_3 }}</a>
                        </div>
                    </template>
                </zw-table>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import commonTable from '@/bundles/erika_common_bundle/mixins/common-table'
import tableDateFilter from '@/bundles/erika_common_bundle/mixins/table-date-filter'

export default {
    name: 'CustomerCalls2',
    mixins: [commonTable, tableDateFilter],
    data() {
        return {
            saveFilters: true,
            dateField: 'date'
        }
    },
    methods: {
        ...mapGetters('CommonTable', ['getCommonTable']),
        myProvider(ctx) {
            return this.commonProviderFetch(ctx, 'CommonTable/fetchTableData', 'getCommonTable');
        },
        ctxBaseParams() {
            return {
                'url': '/reports/customer_calls2',
                'format': 'App\\Managers\\Format\\Reports\\CustomerCalls2'
            }
        },
        getActions() {
            return [
                {
                    title: this.$t('common.button.recalc'),
                    icon: 'calculator',
                    click: () => {
                        this.$root.$children[0].openModal('recalc-report-modal', {report: 'customer_calls_2'}, null, {width: '800px'});
                    }
                },
            ]
        },
        openCustomers(status, data) {
            if (data[status]) {
                const routeData = this.$router.resolve({path: '/customers', query: {id: data[status].join(',')}});
                let href = routeData.href

                window.open(href, '_blank').focus();
            }
        }
    },
}
</script>